import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url.replace('/', '');
    return this.auth.user$.pipe(
      take(1),
      map(user => {
        const isLogged = !!user;
        isLogged && this.auth.storeCurrentUser(user);
        if (isLogged && !this.auth.checkPermissions(user, [url])) {
          environment.debug && console.log('Access denied, code EZ-880');
          this.router.navigateByUrl('/login-stopper');
          return false;
        }
        return isLogged;
      }),
      tap(hasPermission => {
        if (!hasPermission) {
          environment.debug && console.log('Stop access no permissions, code EZ-990');
          this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }

}
