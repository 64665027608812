// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '0.2.9',
  newVersion: '0.3.0',
  ezcloGoogleToken: 'ezclo-TNZSFK',
  companyStoreId: 'pGKKZstzoJyAZC5kxvO5',
  isTest: true,
  apis: {
    extendedZoneNonOfficial: 'https://extendedzonemexico-proxytest.herokuapp.com/extendedZone',
    test: 'https://extendedzonemexico-proxytest.herokuapp.com/location',
    integrations: 'https://us-central1-ezclo-dev.cloudfunctions.net/integrations/api'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAHbWCkXHpvY13R7gVlXD0kmgesCV-LGoc',
    authDomain: 'ezclo-core.firebaseapp.com',
    databaseURL: 'https://ezclo-core.firebaseio.com',
    projectId: 'ezclo-core',
    storageBucket: 'ezclo-core.appspot.com',
    messagingSenderId: '612664166402',
    appId: '1:612664166402:web:822737864ac96563d73f1b',
    measurementId: 'G-XW0DTNZSFK'
  },
  hereApiKey: 'FANGgX1zCsmlD9iCm9l53KEqLSHnOZ3Vnl-Q5wuzeUk',
  defaultConfigs: {
    pageSize: 30,
    orderDestination: 'asc',
    orderStatus: 5
  },
  root: false,
  debug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
