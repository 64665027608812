import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
  selector: 'mng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'manager';
  public location: any = '/login';

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    private toastController: ToastController) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(() => {
        this.location = this.router.url;
      });
      this.swUpdate.available.subscribe(() => {
        this.presentToastWithOptions();
      });
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Actualización disponible!',
      message: `La versión ${environment.version} está disponible, nos actualizamos?`,
      position: 'top',
      mode: 'ios',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          text: 'Ok',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });
    toast.present();
    toast.onWillDismiss().catch(() => {
      window.location.reload();
    });
  }
}
