<div class="logo">
  <a href="https://ezclo.net" class="simple-text logo-mini">
    <div class="logo-img">
      <img src="./assets/img/logo-ezclo.png" />
    </div>
  </a>
  <a href="https://ezclo.net" class="simple-text logo-normal">
    Ezclo
  </a>
</div>
<div class="sidebar-wrapper">
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{ menuItem.class }} nav-item">
      <a [routerLink]="[menuItem.isAdminPanel? menuItem.path : 'login']">
        <ion-icon *ngIf="menuItem.isIonic" [name]="menuItem.icon" size="medium" slot="start"></ion-icon>
        <i *ngIf="!menuItem.isIonic" class="tim-icons  {{ menuItem.icon }}"></i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
  </ul>
</div>