import { Location } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import { LOGIN_PAGE } from '../../constants/routes';

@Component({
  selector: 'mng-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  @Input() isAdmin = false;
  public sidebarColor = 'primary';
  location: Location;

  constructor(
    location: Location,
    private router: Router,
    private active: ActivatedRoute
  ) {
    this.location = location;
  }

  changeSidebarColor(color: string): void {
    const sidebar = document.getElementsByClassName('sidebar')[0];
    const mainPanel = document.getElementsByClassName('main-panel')[0];

    this.sidebarColor = color;

    if (!!sidebar) {
      sidebar.setAttribute('data', color);
    }
    if (!!mainPanel) {
      mainPanel.setAttribute('data', color);
    }
  }

  changeDashboardColor(color): void {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
      body.classList.add(color);
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAdmin = this.active.firstChild.snapshot.data.isAdmin !== false;
      }
    });
  }

  showSideBar(): boolean {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    return `/${LOGIN_PAGE.path}` !== titlee;
  }
}
