import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

const COMPONENTS = [
  AdminLayoutComponent,
  NavbarComponent,
  SidebarComponent,
  FooterComponent
];
@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    NgbCollapseModule,
    NgbDropdownModule,
    BrowserAnimationsModule,
    IonicModule
  ],
  exports: [
    AdminLayoutComponent
  ]
})
export class CoreModule { }
