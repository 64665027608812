import { environment } from '../../../environments/environment';
import { RouteInfo } from '../models/route.model';

const isTest  = !environment.production;

export const LOGIN_PAGE: RouteInfo = {
  path: 'login',
  title: 'Inicio de Sesión',
  rtlTitle: 'الرموز',
  icon: 'login',
  class: '',
  isAdminPanel: true,
  isIonic: true
};
export const DASHBOARD_PAGE = {
  path: 'dashboard',
  rtlTitle: 'لوحة القيادة',
  title: 'Dashboard',
  icon: 'icon-chart-pie-36',
  class: '',
  isAdminPanel: true
};
export const CLIENT_PAGE = {
  path: 'clients',
  title: 'Clientes',
  rtlTitle: 'الرموز',
  icon: 'icon-bullet-list-67',
  class: '',
  isAdminPanel: true
};
export const QUOTE_PAGE = {
  path: 'quotes',
  title: 'Cotizaciones',
  rtlTitle: 'الرموز',
  icon: 'icon-paper',
  class: '',
  isAdminPanel: true
};
export const ORDER_PAGE = {
  path: 'orders',
  title: 'Pedidos',
  rtlTitle: 'الرموز',
  icon: 'icon-delivery-fast',
  class: '',
  isAdminPanel: true
};
export const USER_PROFILE_PAGE = {
  path: 'user-profile',
  title: 'Perfil de Usuario',
  rtlTitle: 'الرموز',
  icon: 'icon-single-02',
  class: '',
  isAdminPanel: true
};
export const MIGRATION_PAGE = {
  path: 'migration',
  title: 'Migrar Datos',
  rtlTitle: 'الرموز',
  icon: 'icon-spaceship',
  class: '',
  isAdminPanel: true
};

export const ROOT_PAGE = isTest
  ? { ...DASHBOARD_PAGE }
  : { ...CLIENT_PAGE };

export const ROUTES: RouteInfo[] = isTest
  ? [ROOT_PAGE, CLIENT_PAGE, QUOTE_PAGE, ORDER_PAGE, USER_PROFILE_PAGE, MIGRATION_PAGE]
  : [ROOT_PAGE, QUOTE_PAGE, ORDER_PAGE, MIGRATION_PAGE];
