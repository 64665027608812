<ion-app>
  <ion-content fullscreen="true">
  <mng-admin-layout [isAdmin]="!location.includes('login') ">
    <router-outlet></router-outlet>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#73b544" type="ball-pulse-sync" [fullScreen]="true">
      <p style="color: white"> Preparando <img src="assets/img/logo-ezclo.png" width="25" /> EZCLO...</p>
    </ngx-spinner>
  </mng-admin-layout>
</ion-content>
</ion-app>
