export const environment = {
  production: true,
  version: '0.2.8',
  newVersion: '0.2.9',
  ezcloGoogleToken: 'ezclo-TNZSFK',
  companyStoreId: 'pGKKZstzoJyAZC5kxvO5',
  isTest: false,
  apis: {
    extendedZoneNonOfficial: 'https://extendedzonemexico-proxytest.herokuapp.com/extendedZone',
    test: 'https://extendedzonemexico-proxytest.herokuapp.com/location',
    integrations: 'https://us-central1-ezclo-dev.cloudfunctions.net/integrations/api'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAHbWCkXHpvY13R7gVlXD0kmgesCV-LGoc',
    authDomain: 'ezclo-core.firebaseapp.com',
    databaseURL: 'https://ezclo-core.firebaseio.com',
    projectId: 'ezclo-core',
    storageBucket: 'ezclo-core.appspot.com',
    messagingSenderId: '612664166402',
    appId: '1:612664166402:web:822737864ac96563d73f1b',
    measurementId: 'G-XW0DTNZSFK'
  },
  hereApiKey: 'FANGgX1zCsmlD9iCm9l53KEqLSHnOZ3Vnl-Q5wuzeUk',
  defaultConfigs: {
    pageSize: 30,
    orderDestination: 'asc',
    orderStatus: 5
  },
  root: false,
  debug: false
};
