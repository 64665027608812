import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../constants/routes';
import { RouteInfo } from '../models/route.model';

@Component({
  selector: 'mng-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: RouteInfo[];

  constructor() {}

  ngOnInit(): void{
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  isMobileMenu(): boolean {
     return !(window.innerWidth > 991);
  }
}
