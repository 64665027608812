import { AdminGuard } from './core/guards/admin.guard';
import { ROOT_PAGE } from './core/constants/routes';
import { CanReadGuard } from './core/guards/can-read.guard';
import { LoggedGuard } from './core/guards/logged.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: ROOT_PAGE.path, pathMatch: 'full'},
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    data: {isAdmin: false}
  },
  {
    path: 'login-step',
    loadChildren: () => import('./pages/login-step/login-step.module').then(m => m.LoginStepModule),
    data: {isAdmin: false}
  },
  {
    path: 'login-stopper',
    loadChildren: () => import('./pages/login-stopper/login-stopper.module').then(m => m.LoginStopperModule),
    data: {isAdmin: false}
  },
  {
    path: 'clients',
    loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quotes',
    loadChildren: () => import('./pages/quotes/quotes.module').then(m => m.QuotesModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'migration',
    loadChildren: () => import('./pages/migration/migration.module').then(m => m.MigrationModule),
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
