<div class="wrapper" *ngIf="showSideBar()">
  <div class="sidebar" [attr.data]="sidebarColor">
    <mng-sidebar  *ngIf="showSideBar()"></mng-sidebar>
  </div>
  <div class="main-panel" [attr.data]="sidebarColor" *ngIf="showSideBar()">
    <mng-navbar [isAdmin]="isAdmin"></mng-navbar>
    <main>
        <router-outlet></router-outlet>
    </main>
    <mng-footer></mng-footer>
  </div>
</div>
<div class="wrapper" *ngIf="!showSideBar()">
  <main>
  <router-outlet></router-outlet>
</main>
</div>
<div class="fixed-plugin" *ngIf="isAdmin && false">
  <div class=" show-dropdown" ngbDropdown>
    <a data-toggle="dropdown" ngbDropdownToggle>
      <i class="tim-icons icon-settings-gear-63"></i>
    </a>
    <ul ngbDropdownMenu>
      <li class=" header-title">Tema</li>
      <li class=" adjustments-line">
        <a class=" switch-trigger background-color" href="javascript:void(0)">
          <div class=" badge-colors text-center">
            <span class=" badge filter badge-danger" [ngClass]="{'active':sidebarColor==='red'}"
              (click)="changeSidebarColor('red')">
            </span>
            <span class=" badge filter badge-primary" [ngClass]="{'active':sidebarColor==='primary'}"
              (click)="changeSidebarColor('primary')">
            </span>
            <span class=" badge filter badge-info" [ngClass]="{'active':sidebarColor==='blue'}"
              (click)="changeSidebarColor('blue')"> </span>
            <span class=" badge filter badge-success" [ngClass]="{'active':sidebarColor==='green'}"
              (click)="changeSidebarColor('green')">
            </span>
          </div>
          <div class=" clearfix"></div>
        </a>
      </li>
      <li class=" adjustments-line text-center color-change">
        <span class=" color-label"> MODO CLARO </span>
        <span class=" badge light-badge mr-2" (click)="changeDashboardColor('white-content')"> </span>
        <span class=" badge dark-badge ml-2" (click)="changeDashboardColor('black-content')"> </span>
        <span class=" color-label"> MODO OSCURO </span>
      </li>
    </ul>
  </div>
</div>
