import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mng-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  version = environment.production? environment.version : `DEV-${environment.version}`;

  constructor() {}

  ngOnInit(): void {}
}
