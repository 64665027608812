import { Roles } from './../models/user.model';
import { environment } from '../../../environments/environment.prod';
import { User } from '../models/user.model';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ROUTES } from '../constants/routes';
import { RouteInfo } from '../models/route.model';

const UKEY = 'EZ_UKEY';
const UNAME = 'EZ_UNAME';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  rolesCached: any = {};

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          localStorage.setItem(UKEY, user.uid);
          localStorage.setItem(UNAME, user.email);
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          localStorage.removeItem(UKEY);
          localStorage.removeItem(UNAME);
          return of(null);
        }
      })
    );
  }

  public getCurrentUserId(): string {
    return localStorage.getItem(UKEY);
  }

  public getCurrentUserEmail(): string {
    return localStorage.getItem(UNAME);
  }

  public storeCurrentUser(user) {
    const crypted = btoa(`${JSON.stringify(user)}_${UKEY}`);
    localStorage.setItem('UKEYROLE', crypted);
  }

  public getCurrentUserInfo(): User {
    const roleUID = localStorage.getItem('UKEYROLE');
    if(!roleUID) {
      return;
    }
    const decrypted = atob(roleUID).replace(`_${UKEY}`, '');
    const info = JSON.parse(decrypted);
    return info;
  }

  public googleSignIn(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.afAuth.signInWithPopup(provider);
  }

  public signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async updatePermissions(token: string): Promise<void> {
    const ukey = localStorage.getItem(UKEY);
    if(!token) {
      return;
    }
    if (!ukey || ukey === null) {
      return;
    }
    const roles: Roles = {
      agent: token.toLowerCase() === `${environment.ezcloGoogleToken}-${environment.companyStoreId}`.toLowerCase(),
      admin: token === `${environment.ezcloGoogleToken}-${environment.companyStoreId}`,
      onlyRead: true
    };
    const permissions = this.getPermissionsByRoles(roles);
    return this.afs.doc<User>(`users/${ukey}`).update({ roles, permissions });
  }

  private getPermissionsByRoles(roles: Roles): string[] {
    const clients = roles.agent || roles.admin ? ['clients-ezclo']: [];
    const dashboard = roles.agent || roles.admin || roles.client || roles.directive ? ['dashboard-ezclo']: [];
    return [...clients, ...dashboard, 'user-profile-ezclo'];
  }

  async signOut(): Promise<void> {
    return await this.afAuth.signOut();
  }

  public canRead(user: User): boolean {
    const allowed = ['onlyRead', 'admin', 'agent', 'directive', 'provider'];
    return this.checkAuthorization(user, allowed);
  }

  public canEdit(user: User): boolean {
    const allowed = ['admin', 'agent'];
    return this.checkAuthorization(user, allowed);
  }

  public canDelete(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  public isAdmin(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  public isAdminCurrentUser(): boolean {
    const allowed = this.getCurrentUserInfo();
    return !!allowed && allowed.roles['admin'];
  }

  public isAgent(user: User): boolean {
    const allowed = ['agent'];
    return this.checkAuthorization(user, allowed);
  }

  public checkUrlAccess(user: User, url: string): boolean {
    const item: RouteInfo[] = ROUTES.filter((i) => url.includes(i.path));
    if (item.length < 1 || !user.roles) {
      return false;
    }
    // console.log(user, item[0].roles);
    return this.checkAuthorization(user, item[0].roles);
  }

  public hasDashboardAccess(user: User): boolean {
    const allowed = ['admin', 'agent', 'provider', 'directive'];
    return this.checkAuthorization(user, allowed);
  }

  public hasClientMasterAccess(user: User): boolean {
    const allowed = ['admin', 'agent', 'directive'];
    return this.checkAuthorization(user, allowed);
  }

  public isOnlyRead(user: User): boolean {
    const allowed = ['onlyRead'];
    return this.checkAuthorization(user, allowed);
  }

  public isClient(user: User): boolean {
    const allowed = ['client'];
    return this.checkAuthorization(user, allowed);
  }

  public isDirective(user: User): boolean {
    const allowed = ['directive'];
    return this.checkAuthorization(user, allowed);
  }

  public isProvider(user: User): boolean {
    const allowed = ['provider'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) {
      return false;
    }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

  checkPermissions(user: User, permissions: string[]): boolean {
    if (!user || !user.permissions) {
      return false;
    }
    for (const permission of permissions) {
      if (
        user.permissions.filter((p) => permission + '-ezclo' === p).length > 0
      ) {
        return true;
      }
    }
    return false;
  }
}
