<footer class="footer">
  <div class="container-fluid">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link" href="https://ezclo.net" target="_blank">
          <img src="assets/img/logo-ezclo.png" width="25"/> EZCLO v{{version}}
        </a>
      </li>
    </ul>
    <div class="copyright">
      &copy; Developed by
      <a href="https://home.molayadev.com" target="_blank"> MOLAYADEV </a>
      for a better web.
    </div>
  </div>
</footer>
